<template>
  <div class="relatedNewsWrap">
     <!-- 搜尋警示名單或組織 -->
    <el-tooltip class="toggleBtn" placement="top">
      <div slot="content">搜尋警示名單</div>
      <el-button  v-show="!dialogStatus" icon="el-icon-search" type="primary" circle @click="toggle"></el-button>
    </el-tooltip>
    
    <section class="warningSearch" v-show="dialogStatus">
      <div class="btnWrap">
        <el-button class="btn" icon="el-icon-close" type="primary" circle @click="toggle"></el-button>
      </div>
      <div class="searchInput">
        <el-input v-model="searchTemp.Name" placeholder="請輸入警示名單"></el-input>
        <el-button type="primary" @click="doSearch">搜尋</el-button>
      </div>
      <div class="content"> 
        <el-table :data="warningListData" v-loading="loading" borderstyle="width: 100%" border max-height="450">
          <el-table-column prop="order" label="項次" width="50"></el-table-column>
          <el-table-column prop="ListType" label="名單種類" width="80">
            <template slot-scope="scope">{{ listTypeName(scope.row.ListType) }}</template>
          </el-table-column>
          <el-table-column prop="Name" label="警示名單" width="80">
            <template slot-scope="scope">
              <a @click="getFormInfo(scope.row)" class="">{{ scope.row.Name }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="Status" label="狀態">
            <template slot-scope="scope">{{ statusName(scope.row.Status) }}</template>
          </el-table-column>
        </el-table>
      </div>
      <Pagination class="relatedNewsPagination"
        @currentChange="pageChange"
        :pageSize="this.searchTemp.Page_Size"
        :propsCurrentPage="this.searchTemp.Page_Num"
        :totalLists="totalLists"
      ></Pagination>
    </section>
    <div class="formWrap">
      <!-- 表單 -->
      <ProfileForm
        ref="profileForm"
        :formType="decideCaseStatusInfo.warningFormType"
        :data="profileForm"
        :status="componentStatus.profileForm"
      ></ProfileForm>

      <!-- 關聯新聞 -->
      <TabNews
        v-if="componentStatus.tabNews"
        :status="componentStatus.tabNews"
        :TabNewsFlag="TabNewsFlag"
        :newsDataID="propsDataID"
        @handleNewsData="handleNewsData"
      />

      <!-- 附件上傳 -->
      <Attachment
        v-if="componentStatus.attachment"
        ref="attachementForm"
        :data="filesId"
        :status="componentStatus.attachment"
      ></Attachment>

      <!-- 簽核歷程和意見 -->
      <ApprovalRecord
        v-if="componentStatus.approvalRecord"
        ref="approvalRecordForm" 
        :caseStatus="componentStatus.approvalRecord"
        :role="decideCaseStatusInfo.userRole"
      ></ApprovalRecord>

      <!-- 按鈕 -->
      <WarningFormBtn
        :status="componentStatus.warningFormBtn"
        @doFunction="handleSubmit"
      ></WarningFormBtn>
    </div>
  </div>


</template>

<script>
import ProfileForm from "@/components/ProfileForm.vue";
import TabNews from "@/components/Tabnews.vue";
import Attachment from "@/components/Attachment.vue";
import WarningFormBtn from "@/components/WarningFormBtn.vue";
import ApprovalRecord from "@/components/ApprovalRecord.vue";
import Pagination from "@/components/Pagination.vue";
export default {
  components: { ProfileForm, TabNews, Attachment, WarningFormBtn,ApprovalRecord,Pagination},
  data() {
    return {
      dialogStatus:false,
      loading:false,
      totalLists: null,
      warningListData:[],
      profileForm:{},
      filesId:[],
      propsDataID:{},
      submitType:"",
      setInfoTemp:{},//送表單的物件
      TabNewsFlag:false,
      suggestion:{},
      requestIdInfo:{
        Type:this.$route.query.warningFormType,//警示人名或組織
        UserID:this.$store.state.userInfo.UserID,//登入者的ID
        ListID:"",//新增或編輯表單需要的參數
      },
      decideCaseStatusInfo:{
        caseStatus: '',
        permission:'',
        warningFormType: this.$route.query.warningFormType,
        userRole:this.$store.state.userInfo.Role,
      },
      componentStatus:{
        profileForm:"",
        tabNews:"",
        attachment:"",
        warningFormBtn:"",
        approvalRecord:""
      },
      searchTemp:{
        UserID:this.$store.state.userInfo.UserID,
        CreateCom: [this.$store.state.userInfo.ComName],//使用者的公司
        Page_Size: 10, //每頁有幾筆
        Page_Num: 1, //目前頁數
        Status: ["approval"],
        // CreateDept: [],//使用者的部門
        Name:"",
      }

    };
  },
  computed:{
    statusName(){
      return(status)=>{
        let text = "";
        if(status==='edit'){
          text = "編輯中"
        }else if(status==='approval'){
          text = "已覆核"
        }else if(status==='reject'){
          text = "已退回" 
        }else if(status==='wait_approval'){
          text = "待覆核"
        }else if(status==='delete'){
          text = "已刪除"
        }else if(status==='wait_delete'){
          text = "待刪除"
        }
        return text
      }
    },
    listTypeName(){
      return(formType)=>{
        let text = "無";
        if(formType==='people'){
          text = '人名'
        }else{
          text = '組織'
        }
        return text
      }
    }
  },
  methods: {
    doSearch(){
      this.searchTemp.Page_Num = 1;
      this.searchTemp.IsUser = true;
      this.getList();
    },
    isUserDelete(){
      let keys = Object.keys(this.searchTemp);
      keys.forEach((key)=>{
        if(key ==='IsUser'){
          delete this.searchTemp[key]
        }
      })
    },
    pageChange(val) {
      this.isUserDelete();
      this.searchTemp.Page_Num = val;
      this.getList();
    },
    toggle(){
      this.dialogStatus = !this.dialogStatus
    },
    /*業務邏輯 */
    handleCaseStatus(){
      let {caseStatus,userRole,permission} = {...this.decideCaseStatusInfo}
      if(userRole==='operator'){
        this.componentStatus.profileForm = 'previewStatus'
        this.componentStatus.attachment = 'previewStatus'
        this.componentStatus.approvalRecord = caseStatus
        if(caseStatus==='edit' && permission==='True'){
          this.componentStatus.tabNews = 'editStatus'
          this.componentStatus.warningFormBtn = 'cancelAndSave'
        }else if(caseStatus==='edit' && permission==='False'){
          this.componentStatus.tabNews = 'previewStatus'
          this.componentStatus.warningFormBtn = 'cancel'
        }else if(caseStatus==='approval' && permission==='True'){
          this.componentStatus.tabNews = 'previewStatus'
          this.componentStatus.warningFormBtn = 'cancelAndRevise'
        }else{
          this.componentStatus.tabNews = 'previewStatus'
          this.componentStatus.warningFormBtn = 'cancel'
        }
      }
    },
    getFormInfo(item){
      console.log(item);
      let { ListID,ListType,Permission,Status } = {...item}
      if(Status==='edit'){
      this.doGetInfo(ListID,'editinfo')
    }else{
      this.doGetInfo(ListID,'latestinfo')
    }
    this.decideCaseStatusInfo.warningFormType = ListType
    this.decideCaseStatusInfo.permission = Permission
    this.decideCaseStatusInfo.caseStatus = Status
    this.handleCaseStatus()
    },
    getList(){
      this.loading = true
      // if(!this.searchTemp.Name){}
      this.$api.list.searchList(this.searchTemp).then((res) => {
        this.warningListData = res.data
        this.warningListData.forEach((item,index)=>{
          item.order = index+1
        })
        this.totalLists = res.total;
        this.loading = false
      });
    },
    handleNewsData(newsData){
      let existingNewsKeys = Object.keys(newsData);
      existingNewsKeys.forEach((key)=>{
        switch(key){
          case 'searchNews':
            this.setInfoTemp.News = newsData[key];
            break
          case 'searChsan':
            this.setInfoTemp.Sanction = newsData[key];
            break
          case 'searchConstr':
            this.setInfoTemp.Constr = newsData[key];
            break
          case 'searchLabor':
            this.setInfoTemp.Labor = newsData[key];
            break
          case 'searchEnviroment':
            this.setInfoTemp.Enviroment = newsData[key];
            break
        }
      })
      this.TabNewsFlag = false;
      this.doAddInfo()
    },
    async handleSubmit(submitType){
      this.submitType = submitType
      if(submitType==='back'){
        this.$router.go(-1)
      }else if(submitType==='addRelateInfo'){
        this.TabNewsFlag = true;//處理關聯新聞資料
      }else if(submitType==='revise'){
        this.componentStatus.profileForm = 'previewStatus'
        this.componentStatus.tabNews = 'editStatus'
        this.componentStatus.attachment = 'previewStatus'
        this.componentStatus.warningFormBtn = 'cancelAndSave'
      }
    },
    doAddInfo(){
      return new Promise((resolve) => {
        this.$store.dispatch('loadingHandler', true);
        let {ListID,UserID} = this.requestIdInfo;
        this.setInfoTemp.ListID = ListID;
        this.setInfoTemp.UserID = UserID;
        this.setInfoTemp.IsUser = true;
        console.log(this.setInfoTemp);

        this.$api.list.addRelationInfo(this.setInfoTemp).then((res) => {
          if(res==='Success'){
            this.$message({
              message: '儲存成功',
              type: 'success'
            });
          }else{
            this.$message({
              message: '儲存失敗',
              type: 'error'
            });
          }
          resolve();
          this.$router.push({name:'home'})
          this.$store.dispatch('loadingHandler', false);
          });
      });
    },
    doGetInfo(ListID,type){
      this.$store.dispatch("loadingHandler", true);
      this.$api.list[type]({ListID:ListID,IsUser:true}).then((res) => {
        this.requestIdInfo.ListID = res.ListID
        this.profileForm = JSON.parse(JSON.stringify(res))

        if(res.Attachement.length>0){
          this.filesId = JSON.parse(JSON.stringify(res.Attachement))
        }
        if(this.profileForm.Nation.length===0){
          this.profileForm.Nation.push({
            ID: "",
            Nation: "",
          })
        }
        if(this.profileForm.TransName.length===0){
          this.profileForm.TransName.push({
            ID: "",
            TransName: "",
          })
        }
        if(this.profileForm.OtherName.length===0){
          this.profileForm.OtherName.push({
            ID: "",
            OtherName:''
          })
        }
        if(this.profileForm.Work.length===0){
          this.profileForm.Work.push({
            ID: "",
            Occupation: "",
            Title: "",
            ArrivedDt: "",
            LeavedDt: "",
          })
        }
        if(this.profileForm.Contact.length===0){
          this.profileForm.Contact.push({
            ID: "",
            Contact: "",
          })
        }
        if(this.profileForm.Relation.length===0){
          this.profileForm.Relation.push({
            ID: "",
            RelEntity: "",
            RelMemo: "",
          })
        }
        if(this.profileForm.Address.length===0){
          this.profileForm.Address.push({
            ID: "",
            Place: "",
          })
        }
        if(this.profileForm.OrgRecord.length===0){
          this.profileForm.OrgRecord.push({
            ID: "",
            ChangeDt: "",
            ChangeItem: "",
          })
        }
        if(this.profileForm.OrgIndus.length===0){
          this.profileForm.OrgIndus.push({
            ID: "",
            Indus: "",
          })
        }
        let {News,Constr,Enviroment,Labor,Sanction} = {...res}
         let allNewsDataID = {};
        if(News.length!==0){
          allNewsDataID.News = News
        }
        if(Constr.length!==0){
          allNewsDataID.Constr = Constr
        }
        if(Enviroment.length!==0){
          allNewsDataID.Enviroment = Enviroment
        }
        if(Labor.length!==0){
          allNewsDataID.Labor = News
        }
        if(Sanction.length!==0){
          allNewsDataID.Sanction = Sanction
        }
        this.propsDataID = allNewsDataID
        this.$store.dispatch("loadingHandler", false);
      });
      
    },
  },
  mounted() {
    this.handleCaseStatus();
    this.getList()
  },
};
</script>

<style lang="scss" scoped>
.relatedNewsWrap{
  position: relative;
  .toggleBtn{
    position: fixed;
    left: 10px;
    bottom: 10px;
    width: 50px;
    height: 50px;
  }
  .warningSearch{
    position: fixed;
    z-index: 999;
    left: 0px;
    bottom: 0px;
    padding: 2%;
    width: 350px;
    .btnWrap{
      width: 100%;
      text-align: right;
    }
    .searchInput{
      display: flex;
    }
    .relatedNewsPagination{
      width: 100%;
      overflow-x: scroll;
    }
  }
  .formWrap{
    width: 100%;
  }
  a{
    color: black;
    text-decoration:none;
    &:hover{
      text-decoration:none;
      color:blue
    };
    cursor: pointer;
  }
}
</style>
