var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relatedNewsWrap" },
    [
      _c(
        "el-tooltip",
        { staticClass: "toggleBtn", attrs: { placement: "top" } },
        [
          _c("div", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v("搜尋警示名單")
          ]),
          _c("el-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.dialogStatus,
                expression: "!dialogStatus"
              }
            ],
            attrs: { icon: "el-icon-search", type: "primary", circle: "" },
            on: { click: _vm.toggle }
          })
        ],
        1
      ),
      _c(
        "section",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.dialogStatus,
              expression: "dialogStatus"
            }
          ],
          staticClass: "warningSearch"
        },
        [
          _c(
            "div",
            { staticClass: "btnWrap" },
            [
              _c("el-button", {
                staticClass: "btn",
                attrs: { icon: "el-icon-close", type: "primary", circle: "" },
                on: { click: _vm.toggle }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "searchInput" },
            [
              _c("el-input", {
                attrs: { placeholder: "請輸入警示名單" },
                model: {
                  value: _vm.searchTemp.Name,
                  callback: function($$v) {
                    _vm.$set(_vm.searchTemp, "Name", $$v)
                  },
                  expression: "searchTemp.Name"
                }
              }),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doSearch } },
                [_vm._v("搜尋")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  attrs: {
                    data: _vm.warningListData,
                    borderstyle: "width: 100%",
                    border: "",
                    "max-height": "450"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "order", label: "項次", width: "50" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "ListType", label: "名單種類", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(_vm._s(_vm.listTypeName(scope.row.ListType)))
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Name", label: "警示名單", width: "80" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.getFormInfo(scope.row)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(scope.row.Name))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "Status", label: "狀態" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _vm._v(_vm._s(_vm.statusName(scope.row.Status)))
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("Pagination", {
            staticClass: "relatedNewsPagination",
            attrs: {
              pageSize: this.searchTemp.Page_Size,
              propsCurrentPage: this.searchTemp.Page_Num,
              totalLists: _vm.totalLists
            },
            on: { currentChange: _vm.pageChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "formWrap" },
        [
          _c("ProfileForm", {
            ref: "profileForm",
            attrs: {
              formType: _vm.decideCaseStatusInfo.warningFormType,
              data: _vm.profileForm,
              status: _vm.componentStatus.profileForm
            }
          }),
          _vm.componentStatus.tabNews
            ? _c("TabNews", {
                attrs: {
                  status: _vm.componentStatus.tabNews,
                  TabNewsFlag: _vm.TabNewsFlag,
                  newsDataID: _vm.propsDataID
                },
                on: { handleNewsData: _vm.handleNewsData }
              })
            : _vm._e(),
          _vm.componentStatus.attachment
            ? _c("Attachment", {
                ref: "attachementForm",
                attrs: {
                  data: _vm.filesId,
                  status: _vm.componentStatus.attachment
                }
              })
            : _vm._e(),
          _vm.componentStatus.approvalRecord
            ? _c("ApprovalRecord", {
                ref: "approvalRecordForm",
                attrs: {
                  caseStatus: _vm.componentStatus.approvalRecord,
                  role: _vm.decideCaseStatusInfo.userRole
                }
              })
            : _vm._e(),
          _c("WarningFormBtn", {
            attrs: { status: _vm.componentStatus.warningFormBtn },
            on: { doFunction: _vm.handleSubmit }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }